<template>
  <div class="user-settings-user horizontal-wrapper"  ref="user_settings_user">
    <div class="userList vertical-wrapper" style="flex: 0.25 0 0">
      <md-field md-clearable style="margin: 0 2.5pt; flex: 0 0 auto;" >
        <label for="">Suche</label>
        <md-input v-model="searchText"></md-input>
      </md-field>
      <div class="vertical-wrapper">

        <list 
          :items="userList.filter(e => e.text.toLowerCase().includes(searchText.toLowerCase()))" 
          :overflow="true" class="sidebar" 
          v-on:item-clicked="user_selected" 
          style="overflow-y: scroll;flex: 1 0 0px;"
          />  
      </div>
      <div style="flex: 0 0 auto">
          <md-button class="md-raised md-primary" v-on:click="addUser">Add</md-button>
      </div>
    </div>
    <div class="settings_wrapper">
      <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <div class="vertical-wrapper" style="margin: 0 5pt">
          <div class="horizontal-wrapper">
            <md-field>
              <label for="">Username/E-mail</label>
              <md-input v-model="user.name" v-on:change="postSettingsUsers()" :disabled="user.vpnAccess"></md-input>
            </md-field>
            <div style="flex: 0 0 auto; margin: auto 2pt" v-on:click="clickOnNameExclamation">
              <md-icon 
                v-if="user.vpnAccess" 
                :md-src="require('../../assets/icons/triangle-exclamation-solid.svg')"  
               >
              </md-icon>
            </div>

          </div>
          
          
          <md-field>
            <label for=""> E-mail</label>
            <md-input v-model="user.email" v-on:change="postSettingsUsers()"></md-input>
          </md-field>
         
          <md-button class="md-primary" v-on:click="sendInvitation">
              Einladungs E-Mail
          </md-button>
        </div>
        <div class="vertical-wrapper" style="margin: 0 5pt">
          <md-field>
            <label for="">Passwort</label>
            <md-input type="password" v-model="user.pw0" ></md-input>
          </md-field>
           <md-field>
            <label for="">Passwort wiederholen</label>
            <md-input type="password" v-model="user.pw1" ></md-input>
          </md-field>
        
          <div class="horizontal-wrapper" style="gap: 5pt;">
            <md-button class="md-primary" style="flex: 1 0 0" v-on:click="set_pw()">Passwort überschreiben</md-button>

            <md-button class="md-accent" style="flex: 1 0 0" v-on:click="deleteUser()">Benutzer entfernen</md-button>

          </div>
      </div>
    

    </div>
    <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <div><md-switch class="md-primary" v-model="user.vpnAccess" v-on:change="postSettingsUsers()">VPN Zugriff</md-switch></div>
        <div></div>

    </div>
    
    <div class="horizontal-wrapper">
      <div class="vertical-wrapper" style="margin: 0 5pt">
          <div class="horizontal-wrapper" style="flex: 0 0 auto">
              <div class="vertical-wrapper" style="flex: 1 0 0">
                <p style="margin: auto">Groups:</p>
              </div>
            
              <md-field md-clearable style="margin: 0 2.5pt; flex: 1 0 0;" >
                <label for="">Suche</label>
                <md-input v-model="groupSearchText"></md-input>
              </md-field>
          </div>
          
          <div class="" style="overflow-y: scroll; margin-bottom: 20pt;">
            <md-switch class="md-primary" style="display: block;" v-for="item in groups.filter(e => e.text.toLowerCase().includes(groupSearchText.toLowerCase()))" :key="item.id" :text="item.text" v-model="item.group_active"  v-on:change="(e) => {item.group_active = e; postSettingsUsers()} ">{{item.text}}</md-switch>
          </div>
      </div>

      <div class="vertical-wrapper" style="margin: 0 5pt">
        <div class="horizontal-wrapper" style="flex: 0 0 auto">
            <div class="vertical-wrapper" style="flex: 1 0 0">
              <p style="margin: auto">Maschines:</p>
            </div>
              
            <md-field md-clearable style="margin: 0 2.5pt; flex: 1 0 0;" >
              <label for="">Suche</label>
              <md-input v-model="maschineSearchText"></md-input>
            </md-field>
        </div>

            <div class="vertical-wrapper overflow-wrapper">
              <md-switch style="flex: 0 0 auto" class="md-primary" v-for="item in maschine_list.filter(e => e.text.toLowerCase().includes(maschineSearchText.toLowerCase()))" :key="item.id " v-model="item.maschine_active" v-on:change="(e) => {item.maschine_active = e; postSettingsUsers()}">{{item.text}}</md-switch>
            </div>
        </div>
    </div>
  </div>

  </div>
</template>
<script>

import list from '@/components/VueComponents/list.vue'
import { mapState } from 'vuex'


export default {
  name: 'user-settings-user',
  components:{
    list
  },
  props:{
    
  },
  methods:{
    user_selected(user){
      this.getSettingsUserDetail(user.id)
    },
    async set_pw(){
      if(this.user.pw0 === this.user.pw1){
        const { swal } = await this.postSettingsUserPassword(this.user.id, this.user.pw0);
        if(swal)
          this.$swal.fire(swal)

      }else{
        this.$swal.fire({ icon: 'warning', title:'Warnung', text: 'Passworteingabe ungültig'})
      }
    },
   
      
  
    addUser(){
      this.$swal({
        title: 'Neuer Benutzer?', 
        icon: 'question', 
       
        showConfirmButton: true,
        confirmButtonText: 'Erstellen',
        showDenyButton: true,
        denyButtonText: 'Abbrechen',

        html:`
          <p></p>
          <text>Möchten Sie einen neuen Benutzer erstellen?</text>
            <br><label>
              Mandat:
            </label>
            <select id="swal-input-mandate" class="swal2-input">
              ${this.mandates.length? this.mandates.map((e, i) => `<option ${!i ? 'selected' : ''} value="${e.id}">${e.name}</option>`).join('\n'): ''}
            </select>
          <input id="swal-input-name" class="swal2-input" placeholder="E-Mail">

          <div style="margin-bottom: 5pt;"></div>
        ` , 
        focusConfirm: false,
        preConfirm: () => {
          const swalInputMandate = document.getElementById('swal-input-mandate')
          return {
            name: document.getElementById('swal-input-name').value,
            IDh_mandate: swalInputMandate ? swalInputMandate.value: 0
          }
        }

      }).then((value) => {
        if (value.isConfirmed){
          // call fetch
          this.postSettingsUsersAdd(value.value.name, value.value.IDh_mandate)
        }
      })
    },
    sendInvitation(){
      this.$swal({
        icon:'question',
        title: 'Einladung zusenden?', 
        text: 'Dieser Email eine einladung senden?',
        
        input: 'email',
        inputValue: this.user.name,
        inputAttributes:{
          placeholder: 'E Mail',

        },

        showConfirmButton: true,
        confirmButtonText: 'Zusenden',

        showDenyButton: true,
        denyButtonText: 'Abbrechen',

      }).then(e => {
        if(e.isConfirmed){
          this.postSettingsUsersInvite(this.user.id, e.value)
        }
      })
    },

    deleteUser(){
      this.$swal({
        icon:'question',
        title: 'Benutzer löschen?', 
        text: `Benutzer ${this.user.name} und alle Zugänge werden gelöscht?`,
        
        showConfirmButton: true,
        confirmButtonText: 'Löschen',

        showDenyButton: true,
        denyButtonText: 'Abbrechen',

      }).then(e => {
        if(e.isConfirmed){
          this.deleteSettingsUser(this.user.id)
        }
      })
    },
    clickOnNameExclamation(){
      this.$swal.fire({
        icon: 'info',
        title: 'Information',
        text: 'Der Benutzername wird von den VPN Zertifikaten verwendet und sollte deshalb nicht geändert werden'
      })
    },

    // GET /settings/user
    async getSettingsUser(){

      // Use this.$hostname for the base URL
      const url = `${this.$hostname}/settings/users`;  
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': this.token, // using this.token
          },
        });

        if(response.status == 200){
          // deserizlize response object
          const users = await response.json()

          users.forEach((item, i) => item.active = !i);

          // if users have at least one item get details from frist one
          if(users.length)
            this.getSettingsUserDetail(users[0].id)

          // override new userList
          this.userList = users;

        }
      } catch (error) {
        console.error('Error posting group data:', error);
      }
    },

    // GET /settings/user/:userId
    async getSettingsUserDetail(userId){

      // Use this.$hostname for the base URL
      const url = `${this.$hostname}/settings/users/${userId}`;  
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': this.token, // using this.token
          },
        });

        if(response.status == 200){
          // deserizlize response object
          const { user, maschines, groups } = await response.json()

          // make accessVPN to Boolean
          user.vpnAccess = user.vpnAccess? true:false;

          // 
          this.user = user;


          this.maschine_list = maschines;

          // group active must be a Boolean
          groups.forEach(e => e.group_active = e.group_active? true: false)
          this.groups = groups;

        }
      } catch (error) {
        console.error('Error posting group data:', error);
      }
    },

    // POST /settings/users/edit
    async postSettingsUsers() {
      const data = {  
        user: this.user,
        maschines: this.maschine_list.map(e => {e.active = e.maschine_active; return e}),
        groups: this.groups.map(e => {e.active = e.group_active; return e}) 
      }
      const url = `${this.$hostname}/settings/users/edit`;  // Use this.$hostname for the base URL
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token, // using this.token
          },
          body: JSON.stringify(data), // Send data in the request body
        });

        if(response.status == 200){
          //this.getSettingsGroups( this.group.id < 0)
        }
      } 
      catch (error) {
        console.error('Error posting group data:', error);
      }
    },

    // POST /settings/users/add
    async postSettingsUsersAdd(name, IDh_mandate){
      const data = { user: {name, IDh_mandate}, }

      const url = `${this.$hostname}/settings/users/add`;  // Use this.$hostname for the base URL
     
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token, // using this.token
          },
          body: JSON.stringify(data), // Send data in the request body
        });

        if(response.status == 200){
            const { user } = await response.json()

            // set list completly inactive and push new element
            if(user){
              this.userList.forEach(e => e.active = false);
              this.userList.push({id: user.id, text: user.name, active:true})
               
              // sort array after insert to keep list sorted
              this.userList.sort((a, b) => a.text.localeCompare(b.text))
            }
        } else if (response.status == 500){
          // check if the response has a swal object and if fire
          const { swal } = await response.json()
          if(swal) 
            this.$swal.fire(swal)
          
        }
      } 
      catch (error) {
        console.error('Error posting group data:', error);
      }
    },

    // POST /settings/users/invite
    async postSettingsUsersInvite(userId, email){
      const data = { user: { id:userId }, email }

      const url = `${this.$hostname}/settings/users/invite`;  // Use this.$hostname for the base URL
     
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token, // using this.token
          },
          body: JSON.stringify(data), // Send data in the request body
        });

        if(response.status == 200){
            const { swal } = await response.json()

            // send success marker
            if(swal){
              this.$swal.fire(swal)
            }
        } else if (response.status == 500){
          // check if the response has a swal object and if fire
          const { swal } = await response.json()
          if(swal) 
            this.$swal.fire(swal)
          
        }
      } 
      catch (error) {
        console.error('Error posting group data:', error);
      }
    },
    // POST /settings/users/password
    async postSettingsUserPassword(userId, password){
      try {
        const response = await fetch(`${this.$hostname}/settings/users/password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.token}`, // Replace with actual token
            },
            body: JSON.stringify({
                pw: password,
                user: { id: userId },
            }),
        });

        const data = await response.json();

        if (response.ok) {
            console.log('Password updated successfully:', data.swal);
            return { success: true, swal: data.swal };
        } else {
            console.error('Failed to update password:', data.swal);
            return { success: false, swal: data.swal };
        }
      }catch (error) { 
          console.error('Error updating password:', error);
          return { success: false, message: 'An error occurred while updating the password.' };
      }
      
    },

    // DELETE /settings/user
    async deleteSettingsUser(userId){
      const url = `${this.$hostname}/settings/users`;  // Use this.$hostname for the base URL

      const data = { user: {id : userId}}
      try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.token}`, // Include the token in the Authorization header
            },
            body: JSON.stringify(data), // Include the userId in the request body
          });

          if (!response.ok) {
              const error = await response.json();
              throw new Error(`Error: ${response.status} - ${error.message}`);
          }

          // refresh user list
          this.getSettingsUser()

          return data;
      } catch (error) {
          console.error('Failed to delete user:', error.message);
          throw error;
      }
    },    
  
  },
  beforeRouteEnter (to, from, next) { 
    next(vm => { 

        vm.getSettingsUser()
        vm.$socket.emit('get-mandates', {token: vm.token})

        next();
    }) 
  },
  computed:{
    ...mapState(['token', 'mandates'])
  },
  sockets:{

  },
  data(){
    return{
      searchText: '',
      maschineSearchText: '',
      groupSearchText: '',


      userList: [],
      user: {
        id: 0, name : '', email: '', pw0: '', pw1: '', vpnAccess: false
      },
      maschine_list: [],
      groups: [],

    }
  }
}
</script>
<style>
.user-settings-user{

  font-size: var(--font1);
}
.user-settings-user .user-list{
  flex: 1 0 auto;
}
.user-settings-user .user-list ul.sidebar{
  display: flex;
  overflow-y: scroll;
}
.user-settings-user .settings_wrapper{
  flex:  1 0 0;
  display: flex;
  flex-direction: column;
}
.user-settings-user .list_wrapper{
  flex:  1 0 0;
  display: flex;
  flex-direction: row;
}
.user-settings-user .list_wrapper > div{
  flex: 1 0 0;

  border: solid 2pt var(--color5);
  margin: 5pt;
  overflow-y: scroll;
  max-height: 51.4vh;
  min-height: 43%;

}


.user-settings-user label input{
  font-size: var(--font1);
}
.user-settings-user .pw_wrapper{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}
.user-settings-user .pw_wrapper label{
  flex: 0 0 520pt;
}
.user-settings-user .user_wrapper{
  display: flex;
  flex-direction: row;
}
.user-settings-user .user_wrapper .wrapper{
  border: 2pt solid var(--color5);
  flex: 1 0 0;
  margin: 5pt
}
.user-settings-user .user_wrapper .wrapper p{
  text-align: left;
  margin: 2pt;
}
.user-settings-user .user_wrapper .wrapper label{
  text-align: left;
  
}
.user-settings-user .group-maschine-wrapper{
  flex: 1 0 0;
}
.overflow-wrapper{
  flex: 1 0 0;
  overflow-y: scroll;
}
</style>