<template>
    <div>
        <div class="horizontal-wrapper">
            <div></div>
            <md-button v-on:click="openDialog">Templates Öffnen</md-button>
            <div></div>
        </div>


        <md-dialog :md-active="open" class="" v-on:md-closed="open = false" v-on:md-clicked-outside="open = false">
            <md-dialog-title>Dashboard Templates</md-dialog-title>
            <div style="overflow-y:scroll; padding: 0 24px; flex: 1 0 auto" class="vertical-wrapper">
                
                <div class="vertical-wrapper" style="flex: 0 0 auto; margin: 24px">
                    <div class="vertical-wrapper">
                        <span>
                            Dashboard Template auswählen
                        </span>
                        <md-field>
                            <label for="">Template</label>
                            <md-select v-model="template">
                                <md-option v-for="t in templates" :key="t.key" :value="t.value">{{ t.name }}</md-option>
                            </md-select>
                        </md-field>
                    </div>

                    <md-dialog-actions style="flex: 0 0 auto; padding: 0" class="horizontal-wrapper">
                        <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="open=false" >Schließen</md-button>
                        <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="applyTemplate" >Übernehmen</md-button>
                    </md-dialog-actions>
                </div>

            </div>
        

            
        </md-dialog>


    </div>
</template>    
<script>
import { mapState } from 'vuex';

export default{
    name: 'template-dialog',
    props: { maschine: Object },
    methods:{
        openDialog(){
            this.open = true

            this.getDashboardTemplates()
        },
        async applyTemplate(){
            const templateName = this.templates.find(e => this.template == e.value ).name


            const { isConfirmed } = await this.$swal.fire({ 
                icon: 'warning', 
                title: 'Template Aktivieren?', 
                text: `Sind Sie sich sicher dass sie das Template ${templateName} activieren möchten?`,
                showConfirmButton: true,
                showDenyButton: true,

                denyButtonText: 'Nein',
                confirmButtonText: 'Ja'
            })
            console.log(isConfirmed);
            if(isConfirmed)
                this.postDashboardActivateTemplate()
        },

        async getDashboardTemplates(){
            const url = `${this.$hostname}/dashboard/templates`
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': this.token, // using this.token
                    },
                });

                // stop here if status isnt 200
                if(response.status != 200){
                    return
                }
                const templates = await response.json()

                this.templates = templates.map(e => { return {name: e.replaceAll('-', ' '), value: e, key: this.$uuid.v4()} })
                if(templates.length)
                    this.template = templates[0]

            }catch(e){
                console.log(e);
            }

        },

        async postDashboardActivateTemplate(){
            const url = `${this.$hostname}/dashboard/activate-template`
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token, // using this.token
                    },
                    body: JSON.stringify({
                        IDh_maschine: this.maschine.id,
                        template: this.template
                    })
                });

                // stop here if status isnt 200
                if(response.status != 200){
                    console.log('Failed');
                    return
                }
                this.open = false;
                this.$emit('success')
                

            }catch(e){
                console.log(e);
            }
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        ...mapState(['display'])
    },
    data(){
        return{
            open: false,

            templates: [ { name: 'UF Template', key: this.$uuid.v4()}, { name: 'RO Template', key: this.$uuid.v4()} ],
            template: ''
        }
    }
}
</script>