<template>
    <div>
        <div class="icon" style="height: 100%; width: 100%;" :style="`background-image: url(${require('../images/openvpn-svgrepo-com.svg')})`" v-on:click="open = true">

        </div>


    <md-dialog :md-active="open" class="" v-on:md-closed="open = false" v-on:md-clicked-outside="open = false">
        <md-dialog-title>OpenVPN Configuration File</md-dialog-title>
        <div style="overflow-y:scroll; padding: 0 24px; flex: 1 0 auto" class="vertical-wrapper">
            <div style="flex: 0 0 50pt" class="icon" :style="`background-image: url(${require('../images/openvpn-svgrepo-com.svg')})`">

            </div>

            <p>EnwatMon Passwort eingeben:</p>
            <md-field>
                <label for="">Private Key Password</label>
                <md-input type="password" v-model="password" v-on:keyup.enter="requestOPENVPNFile"></md-input>
            </md-field>


            
            <div class="vertical-wrapper" style="flex: 0 0 auto; margin: 24px">
            <md-dialog-actions style="flex: 0 0 auto; padding: 0" class="horizontal-wrapper">
                <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="open=false" >Schließen</md-button>
                <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="requestOPENVPNFile" >Herunterladen</md-button>
            </md-dialog-actions>
        </div>

        </div>
      

        
    </md-dialog>

    </div>
</template>

<script>
export default{
    name: 'openvpn-dialog.vue',
    props: { },
    computed:{
        token(){
            return this.$store.getters.token
        },
    },
    methods:{
        requestOPENVPNFile(){
            fetch(`${this.$hostname}/user/request-vpn-certificate`, {
                    method: 'POST', 
                    headers: { authorization: this.token, "Content-Type": "application/json", },
                    body: JSON.stringify({password : this.password})
            }).then(async (res) => {

                if(res.status == 200){
                    const {user, content } = await res.json()

                   // Create a blob from the text content
                    const blob = new Blob([content], { type: 'application/octet-stream' });

                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    //console.log(data);
                    link.setAttribute('download', `${user}.ovpn`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                
                }else{

                    const { swal } = await res.json();
                    if(swal)
                        this.$swal.fire(swal)
                } 

            }).catch()
        }
    },

    data(){
        return {
            open:  false,
            password: ''
        }
    }

}
</script>