<template>
    <div class="vertical-wrapper" style="overflow-y: hidden;">
        <user-header v-on:show_maschines="burger_menu_clicked" v-on:open-editor="openEditor" v-on:open-messaging="openMessaging" ref="user_header" />

        <maschines :class="{ 'not_show': !display.showMaschines }" v-on:maschine-selected="burger_menu_clicked(false)" />

        <router-view>

        </router-view>
    </div>
</template>
<script >
import { mapState } from "vuex";

import userHeaderVue from '../components/user_header.vue'
import maschinesVue from './maschines.vue'
export default{
    name: 'main-view',
    components: {
        'user-header': userHeaderVue,
        'maschines': maschinesVue,
    },
    methods: {
        openEditor(){
            this.$store.commit('display/set-editor-mode', !this.display.editorMode)
        },
        openMessaging(){
            this.$router.push({ name: 'messaging-dialog'})
        },
        burger_menu_clicked(open) {
            if (this.$isMobile()) {
                //console.log('burger menu clicked', open, this.burger_menu_clicked.caller);
                this.showKategories = open
                this.$store.commit('display/set-show-kategories', open)

                if (!open) {
                    this.$refs.user_header.close_burger();
                    this.maschine_overview = false
                    this.$store.commit('display/set-show-maschines', false)


                } else
                    this.$refs.user_header.open_burger();


                return;
            }

            this.$store.commit('display/set-show-maschines', open)

            if (!open) {
                this.$refs.user_header.close_burger();
                this.charts = []
                this.maschine_overview = false
            } else {
                this.$refs.user_header.open_burger();

            }
        },
    },
    computed: {
        token() {
            return this.$store.getters.token
        },
        editorMode(){
            return this.$store.getters.getEditorMode
        },

        pushManagerStatus(){
            return this.$store.getters.getPushManagerStatus;
        },
        pushManagerRegistration(){
            return this.$store.getters.getPushManagerRegistration;
        },
      
        ...mapState(["display"]),
    },
    data(){
        return{
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$socket.emit('get-maschine-search-attributes', { token: vm.token })
            vm.$store.dispatch('push-manager-registration')


            //vm.$store.dispatch('get-mandates')
            vm.$store.dispatch('get-user-details')

            //console.log('Push ManagerRegistration ', vm.token, vm.pushManagerRegistration, vm.pushManagerStatus);
            next();
        })
    },
}
</script>