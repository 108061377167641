
import Vue from 'vue'
import Vuex from 'vuex'
import displayStore from './displayStore'
import globalViewStore from './globalViewStore'
import realtimeStore from './realtimeStore'


Vue.use(Vuex)
const store = function createStore() {
    const userToken = Vue.prototype.$cookies.get('user-token')
    return new Vuex.Store({
        modules: {
            'display': displayStore,
            'globalView': globalViewStore,
            'realtime': realtimeStore,
        },
        state: () => ({
            token: userToken,
            urlAccessToken: '',
            admin: 0,

            qrCodeAccessInfo: { name: '', surname: '', company: '', street: '', postalCode: '', location: '', phone: '', email: '' },
            
            mandates: [],

            maschines:[],
            maschineSearchAttributes: [],

            editorMode: false,
            showMaschines: false,
            showKategories: false,

            kategories: [],
            messageFrames: [],

            globalDashboards: [],

            pushManagerStatus: '',
            pushManagerRegistration : null

            
        }),
        mutations: {
            increment(state) {
                state.count++
            },

            'set-token': function (state, token) {
                state.token = token;
            },
            'set-admin': function(state, admin){
                state.admin = admin
            },
            'set-qrcode': function (state, qrcode) {
                state.qrcode = qrcode
            },
            'set-url-access-token': function(state, accessToken){
                state.urlAccessToken = accessToken;
            },
   
           
            'set-maschines': function(state, maschines){
                state.maschines = []

                maschines.forEach(e => {
                    e.key = Vue.prototype.$uuid.v4()
                    state.maschines.push(e)
                })
            },
            'set-maschines-status': function (state, maschines) {
                state.maschines.forEach(item => item.connect = maschines.some(e => e.id == item.id))
            },


            //data setters
            'set-kategories': function(state, kategories){
                state.kategories = kategories
            },
            'set-message-frames': function(state, messageFrames){
                state.messageFrames = messageFrames
            },

            'set-global-dashboards': function(state, globalDashboards){
                state.globalDashboards = []

                globalDashboards.forEach(e => {
                    state.globalDashboards.push(e)
                });
            },

            'set-maschine-search-attributes': function(state, maschineSearchAttributes){
                state.maschineSearchAttributes = [];

                maschineSearchAttributes.forEach(MSA => {
                    state.maschineSearchAttributes.push(MSA)
                })
            },

            'set-mandates': function(state, mandates){
                state.mandates = mandates;
            },

            'set-push-manager-status': function(state, status){
                state.pushManagerStatus = status
            },
            'set-push-manager-registration': function(state, registration){
                state.pushManagerRegistration = registration
            }

        },
        actions: {
            'push-manager-registration': function({state}){
                const token = state.token;
                const pushManagerStatus = state.pushManagerStatus;
                const pushManagerRegistration = state.pushManagerRegistration;

                if(token && pushManagerStatus == 'registered' && pushManagerRegistration){

                    fetch(`${Vue.prototype.$hostname}/user/push-manager`, { 
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": token
                        },
                        body: JSON.stringify({ pushManagerRegistration: pushManagerRegistration, deviceInfo:'' })

                    })
                }
            },

            'get-mandates': async function({ state }){
                const token = state.token;
                const hostname = Vue.prototype.$hostname
                //

                const res = await fetch(`${hostname}/mandates`, { 
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                    }
                })

                // if status is ok set user variables
                if(res.status.ok){
                    const body = await res.json()

                    state.mandates = body;
                }

            },

            'get-user-details': async function({ state, commit }){
                const token = state.token;
                const hostname = Vue.prototype.$hostname
                //

                const res = await fetch(`${hostname}/user/details`, { 
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                    }
                })
                console.log('get-user-details', res.status);
                
                // if status is ok set user variables
                if(res.status == 200){
                    const body = await res.json()
                    console.log(body);
                    commit('display/set-access-vpn', body.vpnAccess)
                    
                }

            }
        },
        getters: {
            token(state) {
                return state.token;
            },
            urlAccessToken(state){
                return state.urlAccessToken;
            },
          
            getMaschines(state) {
                return state.maschines
            },
            getKategories(state) {
                return state.kategories
            },
            getMessageFrames(state){
                return state.messageFrames
            },
            getAdmin(state){
                return state.admin
            },
            getGlobalDashboards(state){
                return state.globalDashboards
            },

            getMaschineSearchAttributes(state){
                return state.maschineSearchAttributes;
            },

            getMandates(state){
                return state.mandates
            },

            getPushManagerRegistration(state){
                return state.pushManagerRegistration;
            },
            getPushManagerStatus(state){
                return state.pushManagerStatus;
            }
        }
    })
}
export default store;