import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { uuid } from 'vue-uuid'
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import UUID from "vue-uuid";

Vue.use(UUID);

import './assets/layout.css'

Vue.use(require('vue-moment'));

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import VueNumericInput from 'vue-numeric-input';
Vue.use(VueNumericInput)

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.component('vue-datetime', Datetime);
//Socket.io
import IO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
const inProduction = !(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')
const devServer = 'http://localhost:8002';
//const devServer = 'https://test.enwatmon.de';

Vue.prototype.$hostname = inProduction? '' : devServer;

const optionsVueIO = {
  connection: inProduction ? IO() : IO(devServer)
}

Vue.use(new VueSocketIO(optionsVueIO))
Vue.prototype.$socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

//cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('30d')
Vue.$device_token = window.localStorage.getItem('device-token')
if (!Vue.$device_token) {
  Vue.$device_token = uuid.v4()
  
  window.localStorage.setItem('device-token', Vue.$device_token)
  Vue.$cookies.set('device-token', Vue.$device_token)
}
Vue.$cookies.set('device-token', Vue.$device_token)

Vue.$token = Vue.$cookies.get('user-token')

import myrouter from '@/router/index.js'
Vue.use(VueRouter)

import VueJsonToCsv from 'vue-json-to-csv'



Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.config.productionTip = false

import dictonary from './dictonary.js'
dictonary.currentLanguage = 1
Vue.use(dictonary)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents, {
  touchHoldTolerance: 800,
})
document.addEventListener('touchstart', { passive: true });
Vue.prototype.$isMobile = function () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return window.innerWidth < 800
  }
};
Vue.prototype.$user = {id: 0, name: ''}

import createStore from '@/store/index.js'
const STORE = createStore();
import registerServiceWorker from './registerServiceWorker.js'
registerServiceWorker(STORE);


const DEFAULT_TITLE = 'EnwatMon'
document.title = DEFAULT_TITLE;
new Vue({
  el: '#app',
  router: myrouter,
  store: STORE,
  render: h => h(App) 
});
Vue.$google = window.google

